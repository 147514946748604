@import '../../styles/styleguide.scss';

.active {
  border-bottom: 2px solid $white;
}

.inactive {
  border-bottom: 2px solid transparent;
}

.wrapper {
  align-items: center;
  display: flex;
  height: $spaceMedium;
  justify-content: space-between;
  padding: 0 20px;
  position: absolute;
  top: 32px;
  width: 100%;
  z-index: 3;

  @include medium-up {
    top: 40px;
  }

  @include large-up {
    height: 110px;
    padding: 0 40px;
  }

  @include xl-up {
    padding: 0 $spaceMedium;
  }
}

.wrapperBranded {
  background-color: $blue--dark;
}

.wrapperBrandedLight {
  background-color: rgba(7, 42, 62, 0.65);
}

.wrapperBrandedLightV2 {
  background-color: $blue--dark;
  position: static;

  @include medium-up {
    background-color: rgba(7, 42, 62, 0.65);
    position: absolute;
  }
}

.logoWrapper {
  height: 45px;
  position: relative;
  width: 166px;

  @include large-up {
    height: 80px;
    width: 296px;
  }
}

.mobileNavToggle {
  @include utopia600;

  background-color: transparent;
  background-image: linear-gradient(
    to bottom,
    transparent,
    $white 20%,
    transparent 40%,
    $white 60%,
    transparent 80%,
    $white
  );
  background-position: 0 bottom;
  background-repeat: repeat-x;
  background-size: 2px 6px;
  border: 0;
  color: $white;
  cursor: pointer;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 37px;
  padding: 0;

  @include large-up {
    font-size: 16px;
  }

  @include xl-up {
    display: none;
  }
}

.mobileNavToggleWrapper {
  align-items: center;
  display: flex;
}

.navDesktop {
  display: none;

  @include xl-up {
    display: block;
  }
}

.navDesktop ul {
  display: flex;
  gap: $spaceSmall;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navDesktop a {
  align-items: center;
  color: $white;
  display: inline-flex;
  font-family: $nunito;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  height: 100%;
}

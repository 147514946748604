@import '../../styles/styleguide.scss';

.mobileNavItems {
  background-color: $blue--dark;
  height: 100vh;
  padding: 80px 60px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(300px);
  transition: transform 0.5s ease-in-out;
  width: 300px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-bottom: $spaceSmall;
  }

  a {
    color: $white;
    font-family: $nunito;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
}

.mobileNavItemsOpen {
  transform: translateX(0);
}

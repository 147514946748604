// spacing
$spaceXS: 15px;
$spaceSmall: 30px;
$spaceMedium: 60px;
$spaceLarge: 90px;
$spaceXL: 120px;
$max-width: 1200px;

// colors
$black: #000;
$blue: #276d93;
$blue--dark: #072a3e;
$blue--extraLight: #96C7DD;
$blue--light: #dfecf0;
$grey--light: #f0f0f0;
$grey--medium: #eaeaea;
$grey--dark: #464646;
$red-accent: #d50000;
$teal: #739eb1;
$white: #ffffff;

$primaryColor: $blue--dark;
$secondaryColor: $blue;
$primaryColorInverse: $white;
// fonts
$nunito: 'Nunito Sans', sans-serif;

@mixin utopia400 {
  font-family: utopia-std, serif;
  font-weight: 400;
  font-style: italic;
}

@mixin utopia600 {
  font-family: utopia-std, serif;
  font-weight: 600;
  font-style: italic;
}

// breakpoints
$medium: 768px;
$large: 1024px;
$xl: 1440px;

@mixin medium-up {
  @media (min-width: $medium) {
    @content;
  }
}

@mixin large-up {
  @media (min-width: $large) {
    @content;
  }
}

@mixin xl-up {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin h1 {
  @include utopia600;

  color: $primaryColor;
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 1.13;
  margin: 0;

  @include medium-up {
    font-size: 50px;
    letter-spacing: 2px;
    line-height: 1.04;
  }

  @include large-up {
    font-size: 75px;
    letter-spacing: 3px;
    line-height: normal;
  }
}

@mixin h2 {
  @include utopia600;

  color: $primaryColor;
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: 1.17;
  margin: 0;

  @include medium-up {
    font-size: 34px;
    letter-spacing: 0.75px;
    line-height: 1.12;
  }

  @include large-up {
    font-size: 40px;
    letter-spacing: 1px;
  }
}

@mixin h3 {
  @include utopia600;

  color: $primaryColor;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 1.3;
  margin: 0;

  @include medium-up {
    line-height: 1.5;
  }

  @include large-up {
    font-size: 26px;
  }
}

@mixin h4 {
  @include utopia400;

  color: $primaryColor;
  font-size: 20px;
  line-height: 1.3;
  margin: 0;

  @include medium-up {
    line-height: 1.5;
  }

  @include large-up {
    font-size: 26px;
    line-height: 1.38;
  }
}

@mixin h5 {
  color: $primaryColor;
  font-family: $nunito;
  font-size: 16px;
  font-weight: normal;
  margin: 0;

  @include medium-up {
    font-size: 18px;
    line-height: 1.56;
  }

  @include large-up {
    font-size: 20px;
    line-height: 1.45;
  }
}

@mixin h6 {
  color: $primaryColor;
  font-family: $nunito;
  font-size: 13px;
  font-weight: 800;
  margin: 0;

  @include medium-up {
    font-size: 16px;
    line-height: 1.38;
  }
}

@mixin body {
  color: $primaryColor;
  font-family: $nunito;
  font-size: 13px;
  line-height: 1.54;
  margin: 0;

  @include medium-up {
    font-size: 15px;
  }

  @include large-up {
    font-size: 16px;
  }
}

@mixin small {
  color: $primaryColor;
  font-family: $nunito;
  font-size: 11px;
  line-height: 1.45;
  margin: 0;

  @include medium-up {
    font-size: 12px;
  }

  @include large-up {
    font-size: 14px;
    line-height: 1.57;
  }
}

@mixin link {
  color: $primaryColor;
  font-family: $nunito;
  font-size: 13px;
  font-weight: 800;
  line-height: 1.54;
  margin: 0;
  text-decoration: underline;

  @include medium-up {
    font-size: 15px;
  }

  @include large-up {
    font-size: 16px;
  }
}

@import '../../../styles/styleguide.scss';

.background {
  background-image: url('/404-background@2x.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.content {
  left: calc(50% - 130px);
  position: absolute; 
  top: calc(50% - 50px);
  width: 260px;

  @include medium-up {
    left: calc(50% - 202px);
    width: 404px;
  }

  @include large-up {
    left: calc(50% - 263px);
    top: calc(50% - 50px);
    width: 526px;
  }
}

.link {
  @include small;

  border: 1px solid $white;
  border-radius: 100px;
  color: $white;
  display: inline-block;
  height: 36px;
  line-height: 36px !important;
  margin: 0;
  padding: 0 30px 0 54px;
  position: relative;

  &:before {
    background-image: url('/icon-chevron-left.svg');
    background-repeat: no-repeat;
    content: '';
    height: 11px;
    left: 30px;
    position: absolute;
    top: 11px;
    width: 6px;
  }
}

.text {
  @include h4;

  color: $white;
  margin-bottom: 48px;
  margin-top: 0;
}

.title {
  @include h2;

  color: $white;
  margin-bottom: 8px;
}
